<!--门禁模块--人脸认证信息-->
<template>
  <div id="facialInfo" v-loading="loading" :element-loading-text="$t('facialInfo.a29')">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("facialInfo.a1") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16" type="flex" align="bottom">
          <!-- 状态类型 -->
          <a-col :span="3">
            <a-select v-model="form.status" style="width: 100%">
              <a-select-option :value="2"> {{ $t("facialInfo.a26") }} </a-select-option>
              <a-select-option :value="0"> {{ $t("facialInfo.a27") }} </a-select-option>
              <a-select-option :value="1"> {{ $t("facialInfo.a28") }} </a-select-option>
            </a-select>
          </a-col>
          <!-- 设备名称 -->
          <a-col :span="4">
            <a-input v-model="form.name" allow-clear v-InputText :placeholder="$t('facialInfo.a3')"/>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="4">
            <a-button type="primary" @click="getData">{{ $t("facialInfo.a4") }}</a-button>
          </a-col>
      </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" v-table-size="tableSize">
      <a-table :row-selection="rowSelection" :columns="columns" :dataSource="data" :pagination="pagination" :scroll="size" :rowKey="(record) => record.readerId">
        <template slot="chaozuo" slot-scope="text, record">
          <!-- 单条信息人脸终端待机背景图设置 -->
          <a-button type="primary" @click="() => compile(record)"> {{ $t("facialInfo.a7") }} </a-button>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <a-button type="primary" @click="uploadImage" :disabled="selectedRowKeys.length === 0">{{ $t('facialInfo.a32') }}</a-button>
    </div>
    <!-- 上传背景图对话框 -->
    <BgImageModel :devices="faceDevices"></BgImageModel>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import faceDeviceBgImageModel from "./faceDeviceBgImageModel";
import { getFacialInfo, setCompile } from "../../api/door";
import { tableSort } from "../../utils/utils";
const brands = [
    {no: 0, key: "0@@-@@1", name: "请选择"},
    {no: 1, key: "1@@-@@1", name: "宇泛"},
    {no: 2, key: "2@@-@@1", name: "奇智"},
    {no: 3, key: "3@@-@@1", name: "海康"},
    {no: 4, key: "4@@-@@1", name: "海康-HEOP"}
];
export default {
  data() {
    return {
      form: {
        name: "",
        status: 2,
      },
      data: [],
      loading: false,
      visible: false,
      faceinfo: {
        faceDeviceName: "",
        faceDeviceNumber: "",
        readerName: "",
        deviceBrand: "",
        deviceIp: "",
        devicePassWord: "",
        deviceHeartUrl: "",
        deviceIdentifyUrl: "",
        middlewareUrl: "",
        faceDevice: "",
        siteId: this.$route.query.id,
      },
      tableLoading: false,
      size: { y: 240 },
      disabled: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示5条数据
        showSizeChanger: false, //是否可以改变pagesize
        // pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t("facialInfo.a19")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t("facialInfo.a3"),
          dataIndex: "faceDeviceName",
          sorter: (a, b) => {
            return tableSort(a, b, "faceDeviceName");
          },
          align: "center",
        },
        {
          title: this.$t("facialInfo.a10"),
          dataIndex: "faceDeviceNumber",
          sorter: (a, b) => {
            return tableSort(a, b, "faceDeviceNumber");
          },
          align: "center",
        },
        {
          title: this.$t('facialInfo.a13'),
          dataIndex: "deviceIp",
          sorter: (a, b) => {
            return tableSort(a, b, "deviceIp");
          },
          align: "center",
        },
        {
          title: this.$t('facialInfo.a31'),
          dataIndex: "deviceBrand",
          sorter: (a, b) => {
            return tableSort(a, b, "deviceBrand");
          },
           customRender: (text, record, index) =>
            `${
              (this.getBrandName(text,record))
            }`,
          align: "center",
        },
        {
          title: this.$t("facialInfo.a2"),
          dataIndex: "faceDeviceStatus",
          sorter: (a, b) => {
            return tableSort(a, b, "faceDeviceStatus");
          },
          align: "center",
        },
        {
          title: this.$t("facialInfo.a20"),
          dataIndex: "faceDevice",
          sorter: (a, b) => {
            return tableSort(a, b, "faceDevice");
          },
          align: "center",
        },
        {
          title: this.$t("facialInfo.a21"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          align: "center",
        },
      ],
      faceDeviceImageVisible:false,
      faceDevices:[],
      selectedRowKeys:[],
      selectedRows:[],
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys,selectedRows } = this;
      return {
        selectedRowKeys,
        selectedRows,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox',
        getCheckboxProps: record => ({
            props: {
                disabled: this.getCheckboxStatus(record),// Column configuration not to be checked
                name: record.name,
            },
        }),
      };
    },
  
    deviceType() {
      let deviceBrand = this.faceinfo.deviceBrand;
      switch (deviceBrand) {
        case 1:
          return this.$t("facialInfo.a22");
          break;
        case 2:
          return this.$t("facialInfo.a23");
          break;
        case 3:
          return this.$t("facialInfo.a24");
          break;
        default:
          return "";
          break;
      }
    },
  },
  actived(){
    this.selectedRowKeys = [];
    this.faceDevices = [];
  },
  methods: {
    getCheckboxStatus(record){
      let brand = !(record.deviceBrand===3 || record.deviceBrand===4);
      let ip    = !record.deviceIp || "#"===record.deviceIp;

      return brand || ip;
    },
    getBrandName(no,item){
      let name = '';
      brands.forEach(element => {
        if(no+''===element.no+''){
          name = element.name;
        }
      });

      return name;
    },
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("facialInfo");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - 64 - title
        console.log('tableHeight',tableHeight)
        this.size.y = tableHeight
      }
    },
    // 查询方法
    getData() {
      this.loading = true;
      const query = {
        siteId: this.$route.query.id,
      };
      const body = {
        status: this.form.status,
        name: this.form.name,
      };
      getFacialInfo(body, query)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 表格操作编辑按钮方法
    compile(key) {
      // this.visible = true;
      // this.faceinfo = key;
      this.faceDeviceImageVisible = true;
      this.faceDevices = [];
      this.faceDevices.push(key);
    },
    // 编辑的确定按钮方法
    handleOk() {
      this.tableLoading = true;
      this.disabled = true;
      let data = this.faceinfo;
      delete data.deviceBrand;
      setCompile(data)
        .then((res) => {
          this.disabled = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
            this.visible = false;
            this.tableLoading = false;
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.disabled = false;
          this.tableLoading = false;
          console.log(err);
        });
    },
    // 编辑的取消按钮方法
    handleCancel() {
      this.visible = false;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    uploadImage(){
      this.faceDeviceImageVisible = true;
      this.faceDevices = this.selectedRows;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "BgImageModel":faceDeviceBgImageModel,
  },
};
</script>

<style scoped>
#facialInfo {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 113px;
  padding: 0px 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  width: 100%;
  height: calc(100% - 168px);
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 20px;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-form label {
  white-space: nowrap;
}
</style>